import {
  Badge,
  Button,
  Dialog,
  DialogContent,
  IconButton,
} from "@/components/ui";
import React from "react";
import { Asset } from "@/types";
import { DataTable } from "@/components/DataTable";
import { RowSelectionState } from "@tanstack/react-table";

const AssetSelectDialog = (props: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  value: Record<string, Asset>;
  onValueChange: (value: { label: string; value: string }[]) => void;
}) => {
  return (
    <Dialog open={props.open} onOpenChange={props.onOpenChange}>
      <DialogContent className="w-[75%] h-[75%] gap-0 flex flex-col">
        <div className="h-full flex flex-col">
          <DataTable
            tableName={"assetTable"}
            routeName="api.assets.index"
            routeParams={{
              selectedIds: Object.values(props.value)
                .map((item) => item.old_asset_id)
                .join(","),
            }}
            getRowId={(originalRow: Asset) =>
              `${originalRow.uuid}|${originalRow.code} - ${originalRow.name}`
            }
            selection={{
              enabled: true,
              isRowEnabled: () => true,
              prefill: Object.values(props.value).map(
                (item) => `${item.old_asset_id}|${item.label}`,
              ),
              bulkActions: (
                rowSelectionState: RowSelectionState,
                onDone: () => void,
              ) => (
                <Button
                  onClick={() => {
                    onDone();
                    const assets = Object.keys(rowSelectionState).map((key) => {
                      const [value, label] = key.split("|");
                      return {
                        label,
                        value,
                      };
                    });

                    props.onValueChange(assets);
                    props.onOpenChange(false);
                  }}
                >
                  Save
                </Button>
              ),
            }}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

type AssetSelectProps = {
  value: Record<string, Asset>;
  onChange: (value: { label: string; value: string }[]) => void;
};

export const AssetSelect = (props: AssetSelectProps) => {
  const [dialogOpen, setDialogOpen] = React.useState(false);

  console.log({ props });

  return (
    <div className={"border pl-2 pb-2 flex"}>
      <div className="inline-flex flex-wrap flex-1 mt-2">
        {Object.values(props.value).map((r) => (
          <Badge variant={"outline"} key={r.uuid} className="mr-2">
            {r.label}
          </Badge>
        ))}
      </div>
      <div className={"w-10 mt-2"}>
        <IconButton
          icon={"fa-pencil"}
          variant={"secondary"}
          onClick={(e) => {
            e.preventDefault();
            setDialogOpen(true);
          }}
        />
      </div>
      <AssetSelectDialog
        open={dialogOpen}
        onOpenChange={setDialogOpen}
        value={props.value}
        onValueChange={props.onChange}
      />
    </div>
  );
};
