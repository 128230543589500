import { Link } from "@inertiajs/react";

import { Audit, AuditRow } from "@/types";

import { ALIGN_OPTIONS } from "@radix-ui/react-popper";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "./ui/hover-card";
import { cn } from "@/lib/utils";

export const AuditAssetMenu = (props: {
  audit: AuditRow | Audit;
  href?: string;
  align?: (typeof ALIGN_OPTIONS)[number];
  side?: "bottom" | "top" | "right" | "left" | undefined;
  sideOffset?: number;
  className?: string;
}) => {
  const firstAsset = Object.values(props.audit.assets)[0]?.label || "-";
  const additionalAssets =
    Object.keys(props.audit.assets).length > 1
      ? ` +${Object.keys(props.audit.assets).length - 1}`
      : "";

  return (
    <>
      <HoverCard openDelay={100} closeDelay={300}>
        <HoverCardTrigger href={props.href}>
          <div
            className={cn(
              "inline-flex gap-x-3 items-center text-wrap max-w-72",
              props.className,
            )}
          >
            <span className="truncate">{firstAsset}</span>
            <span className="text-body-text-x-subtle">{additionalAssets}</span>
          </div>
        </HoverCardTrigger>
        {Object.keys(props.audit.assets).length > 1 && (
          <HoverCardContent
            align={props.align || "start"}
            side={props.side || "right"}
            sideOffset={props.sideOffset || 4}
            className="w-full rounded flex flex-col max-h-96 overflow-y-auto gap-y-0.5 p-1"
          >
            {Object.keys(props.audit.assets).map((uuid) => (
              <AssetAuditLink
                key={props.audit.assets[uuid].uuid}
                href={route("inertia.asset.show", {
                  asset: props.audit.assets[uuid].route_key,
                  tab: "overview",
                })}
                label={props.audit.assets[uuid].label}
              />
            ))}
          </HoverCardContent>
        )}
      </HoverCard>
    </>
  );
};

const AssetAuditLink = (props: { href: string; label: string }) => {
  return (
    <div className="flex">
      <Link
        href={props.href}
        className="flex h-full w-full hover:bg-accent rounded"
      >
        <span className="text-body-text-subtle hover:text-body-text-default text-sm px-2 py-1.5">
          {props.label}
        </span>
      </Link>
    </div>
  );
};
